<template>
    <h4>{{ $t('general.empresa') }}</h4>
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <a @click.prevent="abrirConfiguracion('DatosEmpresa')" href="#" class="info-box">
                        <span class="info-box-icon bg-info"><i class="fas fa-building"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.datosempresa') }}</span>
                        </div>
                    </a>
                </div>

                <div class="col-md-4" v-if="permisosusuario.includes('Administración') || permisosusuario.includes('Super Administración') || permisosusuario.includes('Facturación')">
                    <a @click.prevent="abrirConfiguracion('Empresas')" href="#" class="info-box">
                        <span class="info-box-icon bg-info"><i class="fas fa-city"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">{{ $t('general.empresas') }}</span>
                        </div>
                    </a>
                </div>
                <div  class="col-md-4" v-if="permisosusuario.includes('Administración') || permisosusuario.includes('Super Administración')">
                    <a @click.prevent="abrirConfiguracion('Festivos')" href="#" class="info-box">
                        <span class="info-box-icon bg-info"><i class="fa fa-calendar"></i></span>
                        <div class="info-box-content">
                            <span class="info-box-text">Festivos</span>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default({
    props:['permisosusuario'],
    setup() {
        
    },
    methods: {
        abrirConfiguracion(configuracion){
            this.$router.push({
                name: configuracion
            });
        }
    }
})
</script>
